import { graphql, navigate } from 'gatsby';
import React from 'react';
import Layout from 'components/Layout';
import { PageTitle, trackTextButtonClick } from 'helpers/eventTracking';
import { CsHero } from 'types/contentStack';
import {
  PortalUnavailableDetails,
  PortalUnavailableDetailsSubheading,
  StyledFormFooter,
  StyledGrid,
  StyledGridItem,
} from './styles';

type PortalUnavailableProps = {
  data: {
    csPetAccountPortalUnavailable: {
      meta_title: string;
      hero: CsHero;
      next_button_text_contact_us: string;
      next_button_url_contact_us: string;
      portal_unavailable_details: {
        thank_you_subheading: string;
        portal_unavailable_details: string;
      };
    };
  };
};

export const query = graphql`
  query {
    csPetAccountPortalUnavailable {
      meta_title
      hero {
        heading
        subheading
      }
      next_button_text_contact_us
      next_button_url_contact_us
      portal_unavailable_details {
        thank_you_subheading
        portal_unavailable_details
      }
    }
  }
`;

const PortalUnavailable: React.FC<PortalUnavailableProps> = ({
  data: {
    csPetAccountPortalUnavailable: {
      meta_title,
      hero,
      next_button_text_contact_us,
      next_button_url_contact_us,
      portal_unavailable_details,
    },
  },
}) => (
  <Layout
    heading={hero.heading}
    subheading={hero.subheading}
    metaTitle={meta_title}
    pageTitle={PageTitle.AccountPortalUnavailable}>
    <StyledGrid>
      <StyledGridItem desktop={8} tabletLandscape={8}>
        <PortalUnavailableDetailsSubheading>
          {portal_unavailable_details.thank_you_subheading}
        </PortalUnavailableDetailsSubheading>
        <PortalUnavailableDetails
          html={portal_unavailable_details.portal_unavailable_details}
          pageTitle={PageTitle.AccountPortalUnavailable}
        />
      </StyledGridItem>
    </StyledGrid>
    <StyledFormFooter
      contentColumns={{ desktop: 8 }}
      moveNextButton={{
        text: next_button_text_contact_us,
        onClick: () => {
          trackTextButtonClick(PageTitle.AccountPortalUnavailable, 'Contact Us');
          navigate(next_button_url_contact_us);
        },
      }}
      pageTitle={PageTitle.AccountPortalUnavailable}
    />
  </Layout>
);

export default PortalUnavailable;

import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import FormFooter from 'components/FormFooter';
import RichTextWithModal from 'components/RichTextWithModal';

export const PortalUnavailableDetailsSubheading = styled.h2`
  ${fonts.headingSmall};
`;

export const PortalUnavailableDetails = styled(RichTextWithModal)`
  && {
    p {
      ${mediaQuery.tabletLandscape`
        ${fonts.paragraphLarge}
      `}
    }
  }
`;

export const StyledGridItem = styled(GridItem)`
  margin-top: ${spacing(2)};
  margin-bottom: ${spacing(1)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(1)};
  `}
`;

export const StyledGrid = styled(Grid)`
  margin-top: ${spacing(2)};
  margin-bottom: ${spacing(0)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(6)};
  `}
`;

export const StyledFormFooter = styled(FormFooter)`
  margin-top: ${spacing(4)};
`;
